/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import mobileLogo from '../../../assets/ProjectAntelopelogolong.png';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';

import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../../components';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);

  return (
    <div className={css.root}>
      <div>
        <img src={mobileLogo} className={css.mobileModalLogo} alt="logo" />
      </div>
      <div className={css.content}>
        {/* new links */}
        <Link to="/s" className={css.navigationLink}>
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.ourCollection" />
          </span>
        </Link>
        <Link to="/p/artist-list" className={css.navigationLink}>
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.artists" />
          </span>
        </Link>
        <Link to="/p/artist-signup" className={css.navigationLink}>
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.artistSignup" />
          </span>
        </Link>
        <Link to="/p/about" className={css.navigationLink}>
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.aboutUs" />
          </span>
        </Link>
        <div className={css.spacer} />
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
